import React from 'react'
import {renderToString} from 'react-dom/server'
import PrintOrder from 'src/components/hub-and-spoke/print-order'
import PrintOrderManifest from 'src/components/hub-and-spoke/print-order/manifest'
import window from 'global/window'
import { markManifestPrinted, markOrdersPrinted } from 'src/redux/hub/actions'

export const LANE_TYPES = {
  AWAITING_DRIVER: 'AWAITING_DRIVER',
  AWAITING_RUNNER: 'AWAITING_RUNNER',
  INCOMING: 'INCOMING',
  PACKED: 'PACKED',
  PACKING: 'PACKING',
  RECOVERED: 'RECOVERED',
  NOT_RECOVERED: 'NOT_RECOVERED'
}

export const SCREEN_STATES = {
  CANCELED: 'CANCELED',
  PACKER: 'PACKER',
  RUNNER: 'RUNNER'
}

const handlePrinting = (orderPrintHtml) => {
  const x = window.screen.width / 2
  const printWindow = window.open('', 'PrintWindow', `width=800px,height=800px,left=${x}`)
  printWindow.document.body.innerHTML = orderPrintHtml
  printWindow.print()
  printWindow.close()
}

export const printAllOrders = (orders, printManifest) => {
  if (!orders.length) return

  let orderPrintHtml = ''

  if (printManifest) {
    orderPrintHtml += renderToString(<PrintOrderManifest orders={orders} />)
  } else {
    orders.forEach(order => {
      orderPrintHtml += renderToString(<PrintOrder order={order} />)
    })
  }

  handlePrinting(orderPrintHtml)

  // send message to hub after we have printed
  printManifest ? markManifestPrinted(orders) : markOrdersPrinted(orders)
}

export const printOrderAgain = (order) => {
  handlePrinting(renderToString(<PrintOrder order={order} />))
}
