import React, { PureComponent } from 'react'
import { SectionTitle } from 'components/typography'
import Print from 'microcomponents/print-button'
import ClearAll from 'microcomponents/clear-all-button'
import RefreshButton from 'microcomponents/refresh-button'
import SoundIcon from 'microcomponents/sound-icon'
import styled from '@emotion/styled'
import MCColors from 'src/helpers/css-variables'
import { array, bool, func, object, oneOfType, string } from 'prop-types'
const { darkDivider, secondaryDark } = MCColors

export default class Lane extends PureComponent {
  static propTypes = {
    children: oneOfType([array, object]),
    headerText: string,
    isSoundMuted: bool,
    onClickFn: func,
    onSoundIconClickFn: func,
    showClearAll: bool,
    showPrinter: bool,
    showRefresh: bool,
    showSoundIcon: bool,
    subheaderContent: object
  }

  render () {
    const {
      children,
      headerText,
      isSoundMuted,
      onClickFn,
      onSoundIconClickFn,
      showClearAll,
      showPrinter,
      showRefresh,
      showSoundIcon,
      subheaderContent
    } = this.props

    return (
      <div>
        <Header>
          <SectionTitle color='white'>{headerText}</SectionTitle>
          <div>
            {showSoundIcon && <SoundIcon fill={isSoundMuted ? '#5C515A' : ''} size='3em' onClick={() => onSoundIconClickFn()} />}
            {showPrinter && <Print right='2rem' color='#00AAE7' onClick={() => onClickFn()} />}
            {showClearAll && <ClearAll right='2rem' color='#00AAE7' onClick={() => onClickFn()} />}
            {showRefresh && <RefreshButton right='2rem' color='#00AAE7' onClick={() => onClickFn()} />}
          </div>
          {subheaderContent && <Subheader>{subheaderContent}</Subheader>}
        </Header>
        <LaneContainer>
          {children}
        </LaneContainer>
      </div>
    )
  }
}

const Header = styled.div`
  align-items: center;
  display: flex;
  font-size: 20px;
  height: 2rem;
  justify-content: space-between;
  margin-bottom: 5rem;
`

const Subheader = styled.div`
  color: white;
  font-size: 1.2rem;
  margin: 0 1rem;
  & a {
    color: #00AAE7;
  }
`

const LaneContainer = styled.div`
  background-color: ${secondaryDark};
  border-radius: 0.5rem;
  border: 1px solid ${darkDivider};
  height: 100%;
  overflow: auto;
  padding: 1rem;
  width: 35rem;
`
