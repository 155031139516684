import t from './actionTypes'

export function requestLogin () {
  return {
    type: t.REQUEST_LOGIN
  }
}

export function receiveLogin () {
  return {
    type: t.RECEIVE_LOGIN
  }
}

export function requestUsers () {
  return {
    type: t.REQUEST_USERS
  }
}

export function receiveUsers () {
  return {
    type: t.RECEIVE_USERS
  }
}

export function requestPromos () {
  return {
    type: t.REQUEST_PROMOS
  }
}

export function receivePromos () {
  return {
    type: t.RECEIVE_PROMOS
  }
}

export function requestDispensaries () {
  return {
    type: t.REQUEST_DISPENSARIES
  }
}

export function receiveDispensaries () {
  return {
    type: t.RECEIVE_DISPENSARIES
  }
}

export function requestDriver () {
  return {
    type: t.REQUEST_DRIVER
  }
}

export function receiveDriver () {
  return {
    type: t.RECEIVE_DRIVER
  }
}

export function requestDrivers () {
  return {
    type: t.REQUEST_DRIVERS
  }
}

export function receiveDrivers () {
  return {
    type: t.RECEIVE_DRIVERS
  }
}

export function requestTogglePromo () {
  return {
    type: t.REQUEST_TOGGLE_PROMO
  }
}

export function receiveTogglePromo () {
  return {
    type: t.RECEIVE_TOGGLE_PROMO
  }
}

export function requestCatalogItem () {
  return {
    type: t.REQUEST_CATALOG_ITEM
  }
}

export function receiveCatalogItem () {
  return {
    type: t.RECEIVE_CATALOG_ITEM
  }
}

export function requestCatalog () {
  return {
    type: t.REQUEST_CATALOG
  }
}

export function receiveCatalog () {
  return {
    type: t.RECEIVE_CATALOG
  }
}

export function requestSpecies () {
  return {
    type: t.REQUEST_SPECIES
  }
}

export function receiveSpecies () {
  return {
    type: t.RECEIVE_SPECIES
  }
}

export function requestProducts () {
  return {
    type: t.REQUEST_PRODUCTS
  }
}

export function receiveProducts () {
  return {
    type: t.RECEIVE_PRODUCTS
  }
}

export function requestCatalogGroup () {
  return {
    type: t.REQUEST_CATALOG_GROUP
  }
}

export function receiveCatalogGroup () {
  return {
    type: t.RECEIVE_CATALOG_GROUP
  }
}

export function requestCatalogBrand () {
  return {
    type: t.REQUEST_CATALOG_BRAND
  }
}
export function receiveCatalogBrand () {
  return {
    type: t.RECEIVE_CATALOG_BRAND
  }
}

export function requestCatalogMenus () {
  return {
    type: t.REQUEST_CATALOG_MENU
  }
}

export function receiveCatalogMenus () {
  return {
    type: t.RECEIVE_CATALOG_MENU
  }
}

export function requestUser () {
  return {
    type: t.REQUEST_USER
  }
}

export function receiveUser () {
  return {
    type: t.RECEIVE_USER
  }
}

export function requestMenu () {
  return {
    type: t.REQUEST_MENU
  }
}

export function receiveMenu () {
  return {
    type: t.RECEIVE_MENU
  }
}

export function requestCatalogBuilderEta () {
  return {
    type: t.REQUEST_ORDER_BUILDER_ETA
  }
}

export function receiveCatalogBuilderEta () {
  return {
    type: t.RECEIVE_ORDER_BUILDER_ETA
  }
}

export function requestCatalogBuilderQuote () {
  return {
    type: t.REQUEST_ORDER_BUILDER_QUOTE
  }
}

export function receiveCatalogBuilderQuote () {
  return {
    type: t.RECEIVE_ORDER_BUILDER_QUOTE
  }
}

export function requestAdultUseTerritories () {
  return {
    type: t.REQUEST_ADULT_USE_TERRITORIES
  }
}

export function receiveAdultUseTerritories () {
  return {
    type: t.RECEIVE_ADULT_USE_TERRITORIES
  }
}

export function requestTags () {
  return {
    type: t.REQUEST_TAGS
  }
}

export function receiveTags () {
  return {
    type: t.RECEIVE_TAGS
  }
}

export function requestTagTypes () {
  return {
    type: t.REQUEST_TAG_TYPES
  }
}

export function receiveTagTypes () {
  return {
    type: t.RECEIVE_TAG_TYPES
  }
}

export function requestInventory () {
  return {
    type: t.REQUEST_INVENTORY
  }
}

export function receiveInventory () {
  return {
    type: t.RECEIVE_INVENTORY
  }
}

export function requestDeliveryFees () {
  return {
    type: t.REQUEST_DELIVERY_FEES
  }
}

export function receiveDeliveryFees () {
  return {
    type: t.RECEIVE_DELIVERY_FEES
  }
}
