import React, { PureComponent } from 'react'
import { array, bool, func, number, object } from 'prop-types'
import Drawer from '@eaze/react-fast-drawer'

import Lane from 'src/components/hub-and-spoke/lane'
import OrderCard from 'src/components/hub-and-spoke/order-card'
import { LANE_TYPES, printAllOrders, printOrderAgain } from 'helpers/hub'
import { WEB_CLIENTS_BASE_URL } from 'helpers/environment'
import OrderDetailModal from 'components/order-detail-map'

import {
  closeButton,
  drawerContainer,
  hubContainer
} from '../style.js'

export default class Packer extends PureComponent {
  static propTypes = {
    areIncomingOrdersMuted: bool,
    clearOrderFocus: func,
    focusedOrder: object,
    isSelectedDepotMetrcRequired: bool,
    markOrderPacked: func,
    markOrderUnpacked: func,
    ordersAwaiting: array,
    ordersIncoming: array,
    ordersPacked: array,
    ordersPacking: array,
    setOrderFocus: func,
    toggleIncomingOrderSounds: func,
    userId: number
  }

  handleOnClickFn = (orderId) => (event) => {
    const { markOrderPacked, userId } = this.props

    markOrderPacked(orderId, userId)
  }

  render () {
    const {
      areIncomingOrdersMuted,
      clearOrderFocus,
      focusedOrder,
      isSelectedDepotMetrcRequired,
      markOrderUnpacked,
      ordersAwaiting,
      ordersIncoming,
      ordersPacked,
      ordersPacking,
      setOrderFocus,
      toggleIncomingOrderSounds
    } = this.props

    const subheaderContent = (
      <div>
        METRC enabled
        <div>
          <a
            /* eslint-disable-next-line no-undef */
            href={`${WEB_CLIENTS_BASE_URL}/partner-apps/pack`}
            rel='noopener noreferrer'
            target='_blank'
          >
            Get the Packer App
          </a>
        </div>
      </div>
    )

    return (
      <div className={hubContainer}>
        <Lane
          headerText={`Incoming (${ordersIncoming.length})`}
          showPrinter
          showSoundIcon
          isSoundMuted={areIncomingOrdersMuted}
          onSoundIconClickFn={toggleIncomingOrderSounds}
          onClickFn={() => printAllOrders(ordersIncoming)}
        >
          {ordersIncoming.map((order) => {
            return (
              <OrderCard
                type={LANE_TYPES.INCOMING}
                key={order.id}
                order={order}
                setOrderFocus={setOrderFocus}
              />
            )
          })}
        </Lane>
        <Lane
          headerText={`Packing (${ordersPacking.length})`}
          subheaderContent={isSelectedDepotMetrcRequired ? subheaderContent : null}
        >
          {ordersPacking.map((order) => {
            return (
              <OrderCard
                type={LANE_TYPES.PACKING}
                key={order.id}
                order={order}
                onClickFn={
                  isSelectedDepotMetrcRequired ? null : this.handleOnClickFn(order.id)
                }
                printFn={() => printOrderAgain(order)}
                setOrderFocus={setOrderFocus}
              />
            )
          })}
        </Lane>
        <Lane headerText={`Packed - Unassigned (${ordersPacked.length})`}>
          {ordersPacked.map((order) => {
            return (
              <OrderCard
                type={LANE_TYPES.PACKED}
                key={order.id}
                order={order}
                onClickFn={() => markOrderUnpacked(order.id)}
                setOrderFocus={setOrderFocus}
              />
            )
          })}
        </Lane>

        <Lane headerText={`Packed - Assigned (${ordersAwaiting.length})`}>
          {ordersAwaiting.map((order) => {
            return (
              <OrderCard
                type={LANE_TYPES.AWAITING_RUNNER}
                key={order.id}
                order={order}
                onClickFn={() => {}}
                setOrderFocus={setOrderFocus}
              />
            )
          })}
        </Lane>
        <Drawer
          open={focusedOrder.id || false}
          onRequestClose={clearOrderFocus}
          modalElementClass={drawerContainer}
          overlayOpacity={0.9}
          dontApplyListeners
        >
          {focusedOrder.id && <OrderDetailModal order={focusedOrder} />}
          <div className={closeButton} onClick={clearOrderFocus}>
            &#10005;
          </div>
        </Drawer>
      </div>
    )
  }
}
