import { connect } from 'react-redux'

import { getIsHubSelectedDepotMetrcRequired, getOrdersIncoming, getOrdersPacking, getOrdersAwaiting, getOrdersPacked } from 'src/redux/hub/selectors'
import { clearOrderFocus, setOrderFocus } from 'src/redux/orders/actions'
import { getFocusedOrder } from 'src/redux/orders/selectors'
import { getAreIncomingOrdersMuted } from 'src/redux/sound-alerts/selectors'
import { toggleIncomingOrderSounds } from 'src/redux/sound-alerts/actions'

import Packer from './component'

const mapStateToProps = (state, props) => {
  return {
    areIncomingOrdersMuted: getAreIncomingOrdersMuted(state),
    isSelectedDepotMetrcRequired: getIsHubSelectedDepotMetrcRequired(state),
    focusedOrder: getFocusedOrder(state),
    ordersAwaiting: getOrdersAwaiting(state),
    ordersIncoming: getOrdersIncoming(state),
    ordersPacked: getOrdersPacked(state),
    ordersPacking: getOrdersPacking(state)
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    clearOrderFocus: () => dispatch(clearOrderFocus()),
    setOrderFocus: (order) => dispatch(setOrderFocus(order)),
    toggleIncomingOrderSounds: () => dispatch(toggleIncomingOrderSounds())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Packer)
