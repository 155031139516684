import React, { PureComponent } from 'react'
import { array, func, number, shape, string } from 'prop-types'
import OrderCardAction from '../order-card-action'
import OrderDisplayInfo from '../order-display-info'
import { transformToUTCISOString } from 'helpers/date'
import { LANE_TYPES } from 'helpers/hub'
import { mcGreen, mcRed, mcYellow } from 'src/helpers/css-variables'
import styled from '@emotion/styled'

export default class OrderCard extends PureComponent {
  static defaultProps = {
    onClickFn: () => {},
    printFn: () => {}
  }

  static propTypes = {
    onClickFn: func,
    orders: array,
    order: shape({
      id: number.isRequired,
      products: array.isRequired
    }),
    printFn: func,
    setOrderFocus: func,
    type: string.isRequired
  }

  getColor = () => {
    let color = mcGreen
    const { order = {} } = this.props

    if (!order.createdAt) return color

    const orderCreatedAt = Date.parse(transformToUTCISOString(order.createdAt))
    const timeDeltaSeconds = (Date.now() - orderCreatedAt) / 1000

    // lol 7 minutes in seconds is 420
    if (timeDeltaSeconds >= 420 && timeDeltaSeconds < 600) {
      color = mcYellow
    } else if (timeDeltaSeconds >= 600) {
      color = mcRed
    }

    return color
  }

  render () {
    const { type, onClickFn, order = {}, orders = [], printFn, setOrderFocus } = this.props
    const { id: orderId, loading: orderIsLoading, products = [] } = order

    let acceptedBy = {}
    let depotId
    let firstOrder = {}
    let itemQuantity = 0
    let firstLabel = 'ORDER NUMBER'

    /*
    Drivers with canceled orders in their possession will have truthy packedById & manifestId.

    Otherwise, the order is still at the depot and just needs to be unpacked and restocked.
    In this case, manifestId will be null.

    Only show the driver's name on canceled order card if canceled order is in driver's possession.
    */

    const driverHasCanceledOrder = order.acceptedBy && order.packedById && order.manifestId
    const isRestockLanes = type === LANE_TYPES.NOT_RECOVERED || type === LANE_TYPES.RECOVERED
    if (isRestockLanes && driverHasCanceledOrder) {
      firstLabel = order.acceptedBy.displayName
    }

    const isRunnerLane = type === LANE_TYPES.AWAITING_DRIVER
    if (isRunnerLane) {
      firstOrder = orders[0]
      acceptedBy = firstOrder.acceptedBy
      depotId = firstOrder.depotId
      firstLabel = acceptedBy.mobilePhoneDisplay
    } else {
      itemQuantity = products.reduce((a, product) => a + product.quantity, 0)
    }

    return (
      <OrderCardContainer color={this.getColor()}>
        <OrderDisplayInfo
          depotId={depotId}
          driverId={acceptedBy.id}
          label={firstLabel}
          largeText={orderId || acceptedBy.firstName}
          order={order}
          setOrderFocus={setOrderFocus}
          type={type}
        />
        <OrderDisplayInfo
          label={isRunnerLane ? 'ORDERS' : 'ITEMS'}
          largeText={isRunnerLane ? orders.length : itemQuantity}
          orderId={orderId}
          printFn={printFn}
          type={type}
        />
        <OrderCardAction
          onClickFn={onClickFn}
          orderId={orderId}
          orderIsLoading={orderIsLoading}
          orders={orders}
          type={type}
        />
      </OrderCardContainer>
    )
  }
}

const OrderCardContainer = styled.div`
  align-items: center;
  border-radius: 1rem;
  background-color: ${({ color }) => color};
  display: flex;
  height: 10rem;
  margin-bottom: 1rem;
  padding: 2rem;
  position: relative;
`
