import React, { PureComponent } from 'react'
import { array, func, number } from 'prop-types'
import styled from '@emotion/styled'
import { white } from 'src/helpers/css-variables'

import CloneDepotProducts from './clone-depot-products.js'
import AddPeakHoursForm from 'src/components/settings/delivery-fees/add-peak-hours-form'
import { formatPeakHoursTime } from 'src/helpers/peak-hours'

export default class Settings extends PureComponent {
  static propTypes = {
    activeDepots: array,
    activeDispensaries: array,
    deliveryFeesList: array,
    saveDeliveryFees: func,
    selectedDepotId: number
  }

  getDepotTimeZone = (depotId) => {
    const { activeDepots } = this.props
    const depot = activeDepots.find((depot) => depot.id === depotId)
    return depot && depot.timeZone
  }

  createPeakHoursBody = (peakHours, depotId) => {
    const peakHoursList = peakHours.peakHours
    const mappedPeakHoursBody = peakHoursList.map((peakHoursRow) => {
      const depotTimeZone = this.getDepotTimeZone(depotId)
      const updatedRow = {
        depotId: depotId,
        startTime: formatPeakHoursTime(peakHoursRow.dayOfWeek, peakHoursRow.startTime, depotTimeZone),
        endTime: formatPeakHoursTime(peakHoursRow.dayOfWeek, peakHoursRow.endTime, depotTimeZone),
        deliveryFee: peakHoursRow.deliveryFee,
        minimumAmountBypassDeliveryFee: null,
        minimumOrderValue: null,
        remainingAmountBypassDeliveryFee: null
      }
      return updatedRow
    })
    return mappedPeakHoursBody
  }

  handleSubmitDeliveryHoursForm = formData => {
    const { saveDeliveryFees, selectedDepotId } = this.props
    saveDeliveryFees(this.createPeakHoursBody(formData, selectedDepotId), selectedDepotId)
  }

  render () {
    const { deliveryFeesList, activeDepots, activeDispensaries, selectedDepotId } = this.props
    const depotTimeZone = this.getDepotTimeZone(selectedDepotId)
    const dispensaryId = activeDepots.find((d) => d.id === selectedDepotId).dispensaryId
    return (
      <Wrapper>
        <Header>Depot Settings</Header>
        <AddPeakHoursForm
          deliveryFees={deliveryFeesList}
          onSubmitForm={this.handleSubmitDeliveryHoursForm}
          depotTimeZone={depotTimeZone}
        />
        <Header>Clone Depot Products</Header>
        <CloneDepotProducts
          activeDepots={activeDispensaries[dispensaryId].depots}
          selectedDepotId={selectedDepotId}
        />
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  width: 100%;
  max-width: 89.9rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`
const Header = styled.h1`
  font-weight: 600;
  font-size: 2.4rem;
  letter-spacing: 0.033rem;
  color: ${white};
  margin: 5rem 0 3.6rem;
`
