import { combineReducers } from 'redux'

import alerts from 'src/redux/alerts/reducer'

import brandItem from 'src/redux/brand-item/reducer'
import cases from 'src/redux/cases/reducer'
import catalog from 'src/redux/catalog/reducer'
import catalogItem from 'src/redux/catalog/item/reducer'
import catalogStrain from 'src/redux/catalog/strain/reducer'
import catalogSubtype from 'src/redux/catalog/subtype/reducer'
import catalogType from 'src/redux/catalog/type/reducer'
import depotInventories from 'src/redux/depot-inventories/reducer'
import driverMap from 'src/redux/driver-map/reducer'
import driverOrdersMap from 'src/redux/driver-orders-map/reducer'
import driverInventoryMap from 'src/redux/driver-inventory-map/reducer'
import firebase from 'src/redux/firebase/reducer'
import groupItem from 'src/redux/group-item/reducer'
import groups from 'src/redux/groups/reducer'
import menuItem from 'src/redux/menu-item/reducer'
import menus from 'src/redux/menus/reducer'
import catalogBuilder from 'src/redux/catalog-builder/reducer'
import place from 'src/redux/place/reducer'
import products from 'src/redux/products/reducer'
import adultUseTerritories from 'src/redux/territory-management/reducer'
import tags from 'src/redux/tags/reducer'
import deliveryFees from 'src/redux/delivery-fees/reducer'
import brandsMap from 'src/redux/brands-map/reducer'
import depotsMap from 'src/redux/depots-map/reducer'
import speciesMap from 'src/redux/species-map/reducer'

import navigation from 'src/redux/navigation/reducer'
import users from 'src/redux/users/reducer'
import loading from 'src/redux/loading/reducer'
import dispensaries from 'src/redux/dispensaries/reducer'
import deliveries from 'src/redux/deliveries/reducer'
import drivers from 'src/redux/drivers/reducer'
import driverVehicles from 'src/redux/driver-vehicles/reducer'
import orders from 'src/redux/orders/reducer'
import promos from 'src/redux/promos/reducer'
import permissions from 'src/redux/permissions/reducer'
import managePromos from 'src/redux/manage-promos/reducer'
import hub from 'src/redux/hub/reducer'
import soundAlerts from 'src/redux/sound-alerts/reducer'

// these were originally imported from shared-redux, but that package is unmaintained
// so I migrated the relevant files into *-shared-redux directories
import user from 'src/redux/user-shared-redux/reducer'
import depots from 'src/redux/depots-shared-redux/reducer'

export default combineReducers({
  adultUseTerritories,
  alerts,
  brandItem,
  brandsMap,
  cases,
  catalog,
  catalogBuilder,
  catalogItem,
  catalogStrain,
  catalogSubtype,
  catalogType,
  deliveries,
  deliveryFees,
  depotInventories,
  depots,
  depotsMap,
  dispensaries,
  drivers,
  driverMap,
  driverOrdersMap,
  driverInventoryMap,
  driverVehicles,
  firebase,
  groupItem,
  groups,
  hub,
  loading,
  managePromos,
  menuItem,
  menus,
  navigation,
  orders,
  permissions,
  place,
  products,
  promos,
  soundAlerts,
  speciesMap,
  tags,
  user,
  users
})
