import t from './actionTypes'
import { createSocket, sendMessageToHub, unsetSocket } from 'helpers/socket-io'
import errorHandler from 'helpers/error-handler'

export function setSelectedDepotId (depotId) {
  // when user selects a depot, we first unset any previous socket, then connect to the depot namespaced socket
  unsetSocket()
  createSocket(depotId)

  return {
    type: t.SET_SELECTED_DEPOT_ID,
    payload: depotId
  }
}

export function receivePendingOrders ({ orders, ordersToBeRemoved }) {
  return {
    type: t.RECEIVE_PENDING_ORDERS,
    payload: {
      orders,
      ordersToBeRemoved
    }
  }
}

export function receiveCanceledOrders ({ orders }) {
  return {
    type: t.RECEIVE_CANCELED_ORDERS,
    payload: orders
  }
}

export function clearUnpackedCanceledOrders (orders) {
  return {
    type: t.CLEAR_UNPACKED_CANCELED_ORDERS,
    payload: orders
  }
}

export function newConnection (orders) {
  return {
    type: t.NEW_CONNECTION,
    payload: orders
  }
}

export function orderModified ({ orderId, key, value }) {
  return {
    type: t.ORDER_MODIFIED,
    payload: {
      orderId,
      key,
      value
    }
  }
}

export function showError ({ error }) {
  // right now this doesnt do anything, we could throw up an error banner probably
  console.error(error)
  errorHandler(error)
  return {
    type: t.ERROR,
    payload: error
  }
}

export function errorOrderId ({ id }) {
  // right now this doesnt do anything, we could throw up an error banner probably
  return {
    type: t.ERROR_ORDER_ID,
    payload: id
  }
}

export function clearStore () {
  return {
    type: t.CLEAR_STORE
  }
}

export function orderCanceled (id) {
  return {
    type: t.ORDER_CANCELED,
    payload: id
  }
}

export function markDriverManifestPrinted ({ driverId }) {
  return {
    type: t.MARK_MANIFEST_PRINTED,
    payload: driverId
  }
}

export function markOrderLoading (id) {
  return {
    type: t.MARK_ORDER_LOADING,
    payload: id
  }
}

export function markManifestPrinted (orders) {
  if (!orders.length) return console.warn('no orders to print manifest for')

  const driverId = orders[0].acceptedBy.id
  sendMessageToHub({ msgType: t.MARK_MANIFEST_PRINTED, data: { driverId } })
}

export function markOrdersPrinted (orders) {
  sendMessageToHub({ msgType: t.MARK_ORDERS_PRINTED, data: { orders } })
}

export function markOrderPacked (id, userId = null) {
  sendMessageToHub({ msgType: t.MARK_ORDER_PACKED, data: { id, userId } })
  return markOrderLoading(id)
}

export function markOrderUnpacked (id, userId = null) {
  sendMessageToHub({ msgType: t.MARK_ORDER_UNPACKED, data: { id, userId } })
  return markOrderLoading(id)
}

export function refreshCanceledOrders () {
  return (dispatch, getState) => {
    const state = getState()
    const depotId = state.hub.selectedDepotId
    const timeZone = state.depotsMap[depotId].timeZone
    sendMessageToHub({ msgType: t.FETCH_CANCELED_ORDERS, data: { timeZone } })
    dispatch({ type: t.FETCH_CANCELED_ORDERS })
  }
}
