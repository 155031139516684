import React, { PureComponent } from 'react'
import { func, number, object, oneOfType, string } from 'prop-types'

import ROUTES from 'components/router/routes'
import { LANE_TYPES } from 'helpers/hub'
import Print from 'microcomponents/print-button'
import styled from '@emotion/styled'
import { white } from 'src/helpers/css-variables'

export default class OrderDisplayInfo extends PureComponent {
  static propTypes = {
    depotId: number,
    driverId: number,
    label: string,
    largeText: oneOfType([number, string]),
    order: object,
    orderId: number,
    printFn: func,
    setOrderFocus: func,
    type: string
  }

  state = {
    hovering: false
  }

  render () {
    const {
      depotId,
      driverId,
      label,
      largeText,
      order,
      orderId,
      printFn,
      setOrderFocus,
      type
    } = this.props
    const { hovering } = this.state
    const isItemsColumn = label === 'ITEMS'
    const isPackingLane = type === LANE_TYPES.PACKING
    const isRunnerLane = type === LANE_TYPES.AWAITING_DRIVER
    const allowOrderDetails = label === 'ORDER NUMBER' || type === LANE_TYPES.NOT_RECOVERED || type === LANE_TYPES.RECOVERED

    return (
      <div
        onMouseEnter={() => this.setState({ hovering: true })}
        onMouseLeave={() => this.setState({ hovering: false })}
      >
        {hovering && isPackingLane && isItemsColumn
          ? <Print onClick={() => printFn(orderId)} />
          : <LargeTextContainer>
            {isRunnerLane
              ? <LargeTextLink target='_blank' rel='noreferrer' href={`${ROUTES.DASHBOARD}/${depotId}${ROUTES.DELIVERIES}?driver=${driverId}`}>
                {largeText}
              </LargeTextLink>
              : <LargeText useCursorPointer onClick={allowOrderDetails ? () => setOrderFocus(order) : null}>
                {largeText}
              </LargeText>
            }
            <LabelText>{label}</LabelText>
          </LargeTextContainer>
        }
      </div>
    )
  }
}

const LargeTextContainer = styled.div`
  color: ${white};
  display: inline-block;
  font-size: 3rem;
  margin-right: 3rem;
  min-width: 11rem;
`

const LargeText = styled.div`
  ${({ useCursorPointer }) => useCursorPointer && 'cursor: pointer'};
`

const LargeTextLink = styled.a`
  color: ${white};
  overflow-x: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  width: 13rem;
`

const LabelText = styled.div`
  font-size: 1.2rem;
`
