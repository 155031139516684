export default {
  REQUEST_LOGIN: 'REQUEST_LOGIN',
  RECEIVE_LOGIN: 'RECEIVE_LOGIN',

  REQUEST_USERS: 'REQUEST_USERS',
  RECEIVE_USERS: 'RECEIVE_USERS',

  REQUEST_DISPENSARIES: 'REQUEST_DISPENSARIES',
  RECEIVE_DISPENSARIES: 'RECEIVE_DISPENSARIES',

  REQUEST_DRIVER: 'REQUEST_DRIVER',
  RECEIVE_DRIVER: 'RECEIVE_DRIVER',

  REQUEST_DRIVERS: 'REQUEST_DRIVERS',
  RECEIVE_DRIVERS: 'RECEIVE_DRIVERS',

  REQUEST_PROMOS: 'REQUEST_PROMOS',
  RECEIVE_PROMOS: 'RECEIVE_PROMOS',

  REQUEST_TOGGLE_PROMO: 'REQUEST_TOGGLE_PROMO',
  RECEIVE_TOGGLE_PROMO: 'RECEIVE_TOGGLE_PROMO',

  REQUEST_CATALOG_ITEM: 'REQUEST_CATALOG_ITEM',
  RECEIVE_CATALOG_ITEM: 'RECEIVE_CATALOG_ITEM',

  REQUEST_CATALOG: 'REQUEST_CATALOG',
  RECEIVE_CATALOG: 'RECEIVE_CATALOG',

  REQUEST_PRODUCTS: 'REQUEST_PRODUCTS',
  RECEIVE_PRODUCTS: 'RECEIVE_PRODUCTS',

  REQUEST_SPECIES: 'REQUEST_SPECIES',
  RECEIVE_SPECIES: 'RECEIVE_SPECIES',

  REQUEST_CATALOG_GROUP: 'REQUEST_CATALOG_GROUP',
  RECEIVE_CATALOG_GROUP: 'RECEIVE_CATALOG_GROUP',

  REQUEST_CATALOG_BRAND: 'REQUEST_CATALOG_BRAND',
  RECEIVE_CATALOG_BRAND: 'RECEIVE_CATALOG_BRAND',

  REQUEST_CATALOG_MENU: 'REQUEST_CATALOG_MENU',
  RECEIVE_CATALOG_MENU: 'RECEIVE_CATALOG_MENU',

  REQUEST_USER: 'REQUEST_USER',
  RECEIVE_USER: 'RECEIVE_USER',

  REQUEST_MENU: 'REQUEST_MENU',
  RECEIVE_MENU: 'RECEIVE_MENU',

  REQUEST_ORDER_BUILDER_ETA: 'REQUEST_ORDER_BUILDER_ETA',
  RECEIVE_ORDER_BUILDER_ETA: 'RECEIVE_ORDER_BUILDER_ETA',

  REQUEST_ORDER_BUILDER_QUOTE: 'REQUEST_ORDER_BUILDER_QUOTE',
  RECEIVE_ORDER_BUILDER_QUOTE: 'RECEIVE_ORDER_BUILDER_QUOTE',

  REQUEST_ADULT_USE_TERRITORIES: 'REQUEST_ADULT_USE_TERRITORIES',
  RECEIVE_ADULT_USE_TERRITORIES: 'RECEIVE_ADULT_USE_TERRITORIES',

  REQUEST_TAGS: 'REQUEST_TAGS',
  RECEIVE_TAGS: 'RECEIVE_TAGS',

  REQUEST_TAG_TYPES: 'REQUEST_TAG_TYPES',
  RECEIVE_TAG_TYPES: 'RECEIVE_TAG_TYPES',

  REQUEST_INVENTORY: 'REQUEST_INVENTORY',
  RECEIVE_INVENTORY: 'RECEIVE_INVENTORY',

  REQUEST_DELIVERY_FEES: 'REQUEST_DELIVERY_FEES',
  RECEIVE_DELIVERY_FEES: 'RECEIVE_DELIVERY_FEES'
}
